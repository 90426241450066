import React, {useState, useEffect} from 'react';
import {useParams, useLocation, Link} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import '../../Styles/ClubPlayers.css'
import { ColorRing } from 'react-loader-spinner';

function ClubPlayers() {

  /* Changed from useLocation to useEffect for clubName because of problem arising when opening site with link and not coming from other site with "Link to" */
  /*const location = useLocation();  
  var compname = location.state.data[1] */

  var params = useParams();
  var clubslug = params["club"]

  var [data, setData] = useState([])
  var [clubName, setClubName] = useState([])
  var [loaded, setLoaded] = useState(false)

  function formatBirthday(inputBirthday) {
    // Split the input string into an array of year, month, and day
    const parts = inputBirthday.split('-');
  
    // Create a Date object using the parts
    const originalDate = new Date(parts[0], parts[1] - 1, parts[2]);
  
    // Extract day, month, and year from the Date object
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = originalDate.getFullYear();
  
    // Create the formatted string
    const formattedBirthday = `${day}.${month}.${year}`;
  
    return formattedBirthday;
  } 

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();
  
    // Calculate age
    const ageInMilliseconds = currentDate - birthDate;
    const ageInYears = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));
  
    return ageInYears;
  };

  useEffect(() => {
        fetch(`https://noawag.de/public/get/clubplayers/${clubslug}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': '11d12e1b-46a7-47ac-9c92-d6f25f22f1ec' // Replace with your actual API key
          }
        })
          .then(res => {
            if (!res.ok) {
              throw new Error(`API request failed with status ${res.status}`);
            }
            return res.json();
          })
          .then(data => {
            setData(data);
            setClubName(data[0].clubName)
            setLoaded(true);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            // Handle error appropriately (e.g., set an error state)
          });
      }, []);

  //Spieler nach Positionen sortieren (evtl. bei SQL einbauen)
  const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

  const sortByObject = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

  data = data.sort(function(left, right) {
      var city_order = sortByObject[left.position] - sortByObject[right.position];
      return city_order;
  });

  const renderComps = () => {
    return data.map(comp =>
      <div className="col-sm-3 mb-2">
        <div className="card round_corners" style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Link to={`/players/${comp.slug}`} style={{ textAlign: 'center' }}><img className='player_picture_club' src={comp.squaredPictureUrl} alt='player'/></Link>
            <img className='compflag' src={comp.flagUrl} alt='flag' />
          </div>
          <div className="card-body" style={{ flex: 1 }}>
            <h5 className="club-players-title"><Link to={`/players/${comp.slug}`}>{comp.displayName}</Link></h5>
            <p className="club-players-text">Position: {comp.position}</p>
            <p className="club-players-text">Birthday: {formatBirthday(comp.birthDate)} ({calculateAge(comp.birthDate)})</p>      
          </div>          
        </div>
      </div>
    )      
  }

  /* alte Version
  <div class="col-sm-6 mb-2">
    <div class="card round_corners">
      <div class="card-body">            
        <h5 class="card-title"><Link to={`/players/${comp.slug}`}>{comp.displayName}</Link></h5>
        <p class="card-text">Position: {comp.position}</p>            
        <p class="card-text">Birthday: {formatBirthday(comp.birthDate)} ({calculateAge(comp.birthDate)})</p>            
        <img className='compflag' src={comp.avatarPictureUrl} alt='flag' />
        <img className='compflag' src={comp.flagUrl} alt='flag' />
      </div>
    </div>
  </div> */

  return (
    <div className='mainbody'>
      <p className='headline_comp bold'>{clubName}</p>
      <div class="row frame_gap">
        {loaded ? 
          renderComps() 
          :
          <Spinner className='spinner-centered' animation="border" size="xxl"/> 
        }
      </div>

    </div>          
  );

}

export default ClubPlayers;